import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  REACT_APP_FLYWIRE_CODE_OTHER,
  REACT_APP_FLYWIRE_CODE_US,
  REACT_APP_FLYWIRE_SCRIPT_URL, 
  REACT_APP_FLYWIRE_ENV, 
  REACT_APP_FLYWIRE_CALLBACK_URL, 
  REACT_APP_FLYWIRE_CALLBACK_VERSION 
} from '../config';

const UNITED_STATES = 'US';

const PAGE_FIRST_PAYMENT = 'firstPayment';
const PAGE_LINK_PAYMENT = 'linkPayment';
const EMBED_TARGET = 'embedTarget';

const FLYWIRE_PROPERTY__CLOSE_LINK = '--fwpCloseLinkDisplay';
const FLYWIRE_PROPERTY__SORT_BY_TYPE = ["credit_card", "online", "bank_transfer"];
const FLYWIRE_PROPERTY__SORT_BY_CURRENCY = ["foreign", "local"];
const FLYWIRE_OPTION__TOKENIZATION = 'tokenization';
const FLYWIRE_OPTION_OPTIONAL_TOKENIZATION = 'optional_tokenization';

const CSS_PROPERTY_NONE = 'none';
const CSS_PROPERTY_BLOCK = 'block';

const SEPARATOR_GUION = '-';
const SEPARATOR_COMMA = ',';

interface FormFlywareProps {
  objectData: any;
  isModal: boolean;
  retry: any;
  session: any;
  onCompleteFlywire: Function;
}

function FormFlyware(
{ 
  objectData, 
  isModal, 
  retry, 
  session, 
  onCompleteFlywire 
} : FormFlywareProps) {
  const navigate = useNavigate();
  const [inExecution, setInExecution] = useState(false);
  useEffect(() => {
    if (objectData === null || objectData === undefined) {
      return;
    }
    const flywireScriptUrl = REACT_APP_FLYWIRE_SCRIPT_URL;
    if ( retry < 0 ) {
      return;
    }
    
    let divModal = ''
    if ( !isModal ) {
        divModal = `#${EMBED_TARGET}`;
        document.documentElement.style.setProperty(FLYWIRE_PROPERTY__CLOSE_LINK, CSS_PROPERTY_NONE);
    } else {
        document.documentElement.style.setProperty(FLYWIRE_PROPERTY__CLOSE_LINK, CSS_PROPERTY_BLOCK);
    }

    let screen = window.location.href.includes("link") ? PAGE_LINK_PAYMENT : PAGE_FIRST_PAYMENT;
    let recurringType = FLYWIRE_OPTION_OPTIONAL_TOKENIZATION;
    if (objectData.automaticApproval) {
      if (objectData.recurrent) {
        recurringType = FLYWIRE_OPTION__TOKENIZATION;
      } else {
        recurringType = FLYWIRE_OPTION_OPTIONAL_TOKENIZATION;
      }
    } else {
      recurringType = FLYWIRE_OPTION__TOKENIZATION;
    }

    let flywireCode = REACT_APP_FLYWIRE_CODE_OTHER;
    if (objectData.country === UNITED_STATES) {
      flywireCode = REACT_APP_FLYWIRE_CODE_US
    }

    const stateSplit = objectData.state.split(SEPARATOR_GUION);
    const state = stateSplit[1] ? stateSplit[1].trim() : stateSplit[0].trim();
    var config = {    
      env: REACT_APP_FLYWIRE_ENV,
      recipientCode: flywireCode,
      recurringType: recurringType,
      amount: objectData.amount,
      firstName: screen === PAGE_FIRST_PAYMENT ? objectData.firstName : objectData.payerFirstName,
      middleName: screen === PAGE_FIRST_PAYMENT ? objectData.middleName : objectData.payerMiddleName,
      lastName: screen === PAGE_FIRST_PAYMENT ? objectData.lastName : objectData.payerLastName,
      address: objectData.address,
      email: objectData.email,
      phone: objectData.phone,
      city: objectData.cityForFlywire || objectData.city,
      state: state,
      country: objectData.country,
      zip: objectData.zipCode,
      locale: objectData.language,
      recipientFields: {
        invoice_number: objectData.idApplication,
        session_id: session,
        quotation: objectData.idQuotation,
        document_number: objectData.idPerson,
        payer_relation: objectData.relationship,
        payer_type: objectData.payingForPolicy,
        payment_installments: objectData.paymentIds?.join(SEPARATOR_COMMA),
        recurrent: objectData.recurrent,
      },
      paymentOptionsConfig: 
      {
        sort: [
          { type: FLYWIRE_PROPERTY__SORT_BY_TYPE },
          { currency: FLYWIRE_PROPERTY__SORT_BY_CURRENCY },
        ]
      },
      onInvalidInput: function(errors: any) {
        console.error('Flywire[Validations errors]', errors);
      },
      requestPayerInfo: false,
      requestRecipientInfo: false,
      embedTo: divModal,
      onCompleteCallback: async function(args: any) {
        if(screen === PAGE_FIRST_PAYMENT) {
          onCompleteFlywire({"success": true, "args": args, "session": session});
        } else {
          if (inExecution === false) {
            setInExecution(true);
            onCompleteFlywire({"success": true, "args": args});
          }
        }
      },
      onCancel: function() {
        console.log('Flywire [canceled event]', "Flywire Form was cancelled");
      },
      callbackId: objectData.idQuotation,
      callbackUrl: REACT_APP_FLYWIRE_CALLBACK_URL,
      callbackVersion: REACT_APP_FLYWIRE_CALLBACK_VERSION,
    };
    console.log('Flywire Configuration', JSON.stringify(config))
    const target = document.getElementById(EMBED_TARGET);

    if (target && target.firstChild) {
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }
    }

    const loadFlywireScript = (callback: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
      const script = document.createElement('script');
      script.src = flywireScriptUrl ?? '';
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    
    const initializeFlywire = () => {
      if (target && target.firstChild) {
        return; 
      }
      // @ts-ignore
      window.FlywirePayment.initiate(config);
    };
    // @ts-ignore
    if (window.FlywirePayment) {
      initializeFlywire();
    } else {
      loadFlywireScript(initializeFlywire);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, isModal, session, inExecution, navigate]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id={`${EMBED_TARGET}`}></div>
    </div>
  );   
}

export default FormFlyware;