import React, { useCallback, useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import FormFlyware from '../components/FormFlyware';
import LinkOptionContext from '../contexts/LinkOptionContext';
import { getSession } from '../services/session/sessionService';
import { SessionResponse, transformObjectResponse } from '../services/session/types';
import { languageMessages } from 'traductor/FirstPayment';
import Skeleton from '../components/Skeleton';
import Dialog from '../components/OleDialog';
import HTMLRenderer from 'components/GlobalHTMLParser';
import { createSubscription, omit } from 'services/subscription/subscriptionService';
import LanguageContext from 'contexts/LanguageContext';
import FormPersonalData from 'components/FormPersonalData';
import { SubscriptionRequest } from 'services/subscription/types';
import PaymentConfirmation from './PaymentConfirmation';
import Layout from 'Layout';
import { REACT_APP_TIME_REFRESH } from 'config';

function FirstPayment({language}: { language: string }) {
  const location = useLocation();
  // eslint-disable-next-line
  const { changeLanguage } = useContext(LanguageContext);

  const [token, setToken] = useState('');
  const [retry, setRetry] = useState(-1);
  const [selectedLinkOption, setSelectedLinkOption] = useState('');
  const [sessionData, setSessionData] = useState<SessionResponse | null>(null);
  const [objectData, setObjectData] = useState<any>(null);
  const [status, setStatus] = useState<string>();
  const [error, setError] = useState<any>(null);
  // eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVantage, setIsVantage] = useState(false);
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [numCalls, setNumCalls] = useState(0);

  const handleError = useCallback((type: string) => {
    const errorInfo = languageMessages(language, "errorMessages")[type];
    setError({
      title: errorInfo.title,
      subtitle: errorInfo.subtitle,
      buttonText: errorInfo.buttonText,
    });
    setIsOpen(true);
    console.error(type, errorInfo.subtitle);
  }, [language]);

  // eslint-disable-next-line
  const [result, setResult] = useState<any>(null);

  const responseflywire = async(dato:any) => {
    console.log('flywire callback', dato);
    if (dato.success) {
      if (dato?.args.status === 'error') {
        handleError('payment');
        console.error('subscription', dato?.args.error);
        return;
      }

      setResult(dato?.args);
      setSuccess(true);
      setStatus(dato?.args.status)
      if (dato?.args.paymentMethod === 'card' || dato?.args.paymentMethod === 'credit_card'|| dato?.args.paymentMethod === 'recurring') {
        let recurrentOption = objectData.recurrent;
        if (dato?.args.token !== '' && dato?.args.token !== undefined) {
          recurrentOption = true;
        }
        const subscriptionData:SubscriptionRequest = {
          "reference": dato?.args.reference,
          "sig": dato?.args.sig,
          "status": dato?.args.status,
          "payerAmount": dato?.args.payerAmount,
          "payerAmountCurrency": dato?.args.payerAmountCurrency,
          "amount": dato?.args.amount === undefined ?  dato?.args.amount : String(dato?.args.amount),
          "amountCurrency": dato?.args.amountCurrency,
          "paymentMethod": dato?.args.paymentMethod,
          "subscription": {
            "id_quotation": objectData.idQuotation,
            "id_application": objectData.idApplication,
            "recurrent": recurrentOption,
            "paymaster": objectData.payingForPolicy,
            "paymaster_relationship": objectData.payingForPolicy === 'Other' ? objectData.relationship : objectData.payingForPolicy,
          },
          payer: {
            first_name: objectData.firstName,
            last_name: objectData.lastName,
            middle_name: objectData.middleName,
            address1: objectData.address,
            address2: '',
            city: objectData.cityForFlywire || objectData.city,
            state: objectData.state,
            zip: objectData.zipCode,
            country: objectData.country,
            phone: objectData.phone,
            email: objectData.email,
            paymaster: objectData.payingForPolicy,
            paymaster_relationship: objectData.relationship,
          }
        };
        if (dato?.args.type === 'credit' || dato?.args.type === 'debit') {
          subscriptionData.card = {
            token: dato?.args.token,
            type: dato?.args.type,
            brand: dato?.args.brand,
            digits: dato?.args.digits,
            expirationMonth: dato?.args.expirationMonth,
            expirationYear: dato?.args.expirationYear,
          };
        }
        console.log(`subscription(${objectData.recipientCode})`, subscriptionData);
        const res = await createSubscription(subscriptionData, dato?.session, objectData.recipientCode);
        if (!res.success) {
          console.error('subscription(response)', res.error);
        } else {
          console.log('subscription(response)', res.data);
        }
      } else {
        console.warn('subscription', 'Payment method not supported');
      }
    } else {
      handleError('payment');
      console.error('subscription', languageMessages(language, "errorMessages")["payment"].subtitle);
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionToken = queryParams.get('session') ?? '';
    const originVantage = queryParams.get('origin') ?? '';
    setToken(sessionToken);
    setIsVantage(originVantage === 'app-mobile');

    if (!sessionToken || sessionLoaded) {
      if (!sessionToken) {
        handleError('session');
        console.error('session', 'No session token found');
      }
      return;
    }
    
    const fetchData = async () => {
      try {
        if (numCalls > 0) {
          return;
        }
        setNumCalls(numCalls + 1);
        const session: SessionResponse = await getSession(sessionToken);
        if (session.subscriptionHash !== undefined && session.subscriptionHash !== '') {
          handleError('existsSession');
          console.warn('session', 'Session already exists');
          return;
        }

        setSessionData(session);
        setObjectData(transformObjectResponse(session));
        const languageMap: { [key: string]: string } = {
          EN: 'en',
          US: 'en',
          SP: 'es',
          PT: 'pt',
          ES: 'es',
        };
        changeLanguage(languageMap[session.language]);
      } catch (error) {
        handleError('session');
        console.error('session', JSON.stringify(error));
      } finally {
        setSessionLoaded(true);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [location.search, sessionLoaded, language, numCalls, changeLanguage]);

  useEffect(() => {
    if (sessionData) {
      let _object = objectData;
      _object.language = language;
      setObjectData(_object);  
    }
    // eslint-disable-next-line
  }, [language, changeLanguage]);

  const callFormPayment = () => {
    let _object = objectData;
    setObjectData(_object);
    setRetry(retry + 1);
  };

  const changeOption = (newOption: React.SetStateAction<string>) => {
    setSelectedLinkOption(newOption);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    window.location.reload();
  };

  const onReturn = () => {
    if (isVantage) {
      //@ts-ignore
      window.ReactNativeWebView.postMessage("session-back");
      return;
    }
    window.open(objectData.redirectUrl, '_self');
  }

  const onComplete = async() => {
    const res = await omit(token, objectData.idApplication, objectData.idQuotation, objectData.recipientCode);
    if (!res.success) {
      setError({
        title: languageMessages(language, "errorMessages")["payment"].title,
        subtitle: languageMessages(language, "errorMessages")["payment"].subtitle,
        buttonText: languageMessages(language, "errorMessages")["payment"].buttonText,
      });
      setIsOpen(true);
      console.error('omit', JSON.stringify(res.error));
    } else {
      setSuccess(true);
    }
  }

  const onInactive = () => {
    console.warn('activity', 'Inactive session');
    window.location.reload();
  }

  return (
    <Layout inactivityTime={ Number.parseInt(REACT_APP_TIME_REFRESH)} onInactive={onInactive}>
    <LinkOptionContext.Provider value={{ option: selectedLinkOption, changeOption }}>
      <div style={success ? { display: 'block'} : {display: 'none'}}>
        <PaymentConfirmation language={language} tipo={!objectData?.automaticApproval ? 'regular' : (status === 'success' ? 'auto' : 'pending')} field1={objectData?.email}/>
      </div>
      {isOpen && (
        <Dialog
          title={error.title}
          subtitle={error.subtitle}
          buttonText={error.buttonText}
          showClose={false}
          onClose={handleClose}
          onButtonClick={handleButtonClick}
        />
      )}
      {sessionLoaded ? (
        <div className='app' style={success ? {display: 'none'} : {display: 'block'}}>
          <Header paymentType='first' language={language} showLogo={true} />
          <div className='app-body'>
            <div className='img'>
              <div className='return'>
                <div onClick={onReturn}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15" cy="15" r="15" transform="rotate(-180 15 15)" fill="white" fill-opacity="0.5"/>
                  <path d="M17.594 20.1006C17.6834 20.0114 17.7543 19.9055 17.8027 19.7888C17.8511 19.6722 17.876 19.5471 17.876 19.4208C17.876 19.2945 17.8511 19.1695 17.8027 19.0528C17.7543 18.9362 17.6834 18.8302 17.594 18.741L13.8525 14.9996L17.594 11.2581C17.7743 11.0778 17.8756 10.8333 17.8756 10.5783C17.8756 10.3233 17.7743 10.0788 17.594 9.89849C17.4137 9.71819 17.1691 9.61691 16.9142 9.61691C16.6592 9.61691 16.4146 9.71819 16.2343 9.89849L11.8083 14.3246C11.7189 14.4138 11.6479 14.5197 11.5996 14.6364C11.5512 14.753 11.5263 14.8781 11.5263 15.0044C11.5263 15.1307 11.5512 15.2557 11.5996 15.3724C11.6479 15.489 11.7189 15.595 11.8083 15.6842L16.2343 20.1103C16.6008 20.4767 17.2179 20.4767 17.594 20.1006Z" fill="white"/>
                </svg>
                </div>
              </div>
              <img src='icon_first_payment.png' alt='First Payment' style={{ margin: '0 auto', display: objectData?.automaticApproval ? 'block' : 'none' }}></img>
            </div>
            <div className='text'>
              <div className='title' style={{ paddingBottom: objectData?.automaticApproval ? '4px' : '32px' }}>{objectData?.automaticApproval ? languageMessages(language, "titleApproved") : languageMessages(language, "titleRegular")}</div>
              <div className='subtitle'><HTMLRenderer html={objectData?.automaticApproval ? languageMessages(language, "subtitleApproved") : languageMessages(language, "subtitleRegular")}></HTMLRenderer></div>
            </div>
            <div className='paymentInfo'>
              <div className='description'>{languageMessages(language, "description")}</div>
              <div className='amount'> {objectData?.currency} {objectData?.currencySymbol}{sessionData?.amount}/<span>{languageMessages(language, "frequency")[objectData?.paymentFrequency]}</span></div>
            </div>
            {objectData && <FormPersonalData objectData={objectData} onClick={callFormPayment} language={language} buttonText={objectData?.automaticApproval ? languageMessages(language, "payNowAprroved") : languageMessages(language, "payNowRegular")} showRecurrent isFirstPayment />}
            <div className='skip' style={{ display: !objectData?.showOmitPayment ? 'none' : 'block'}}><span onClick={onComplete} style={{cursor: 'pointer'}}>{languageMessages(language, "skip")}</span></div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
      <FormFlyware objectData={objectData} isModal={true} retry={retry} session={token} onCompleteFlywire={responseflywire} />
    </LinkOptionContext.Provider>
    </Layout>
  );
}

export default FirstPayment;