const BASE_URL = process.env.REACT_APP_OLE_APIS || 'http://localhost:8080';
const REACT_APP_FLYWIRE_SCRIPT_URL = process.env.REACT_APP_FLYWIRE_SCRIPT_URL || 'https://checkout.flywire.com/flywire-payment.js';
const REACT_APP_FLYWIRE_ENV = process.env.REACT_APP_FLYWIRE_ENV || 'demo';
const REACT_APP_FLYWIRE_CODE = process.env.REACT_APP_FLYWIRE_CODE || 'OEI';
const REACT_APP_FLYWIRE_CODE_US = process.env.REACT_APP_FLYWIRE_CODE_US || 'OEB';
const REACT_APP_FLYWIRE_CODE_OTHER = process.env.REACT_APP_FLYWIRE_CODE_OTHER || 'OLT';
const REACT_APP_FLYWIRE_CALLBACK_URL = process.env.REACT_APP_FLYWIRE_CALLBACK_URL || 'https://microservices.dev.oleinsurance.com/api-payments/v1/webhooks/flywire/';
const REACT_APP_FLYWIRE_CALLBACK_VERSION = process.env.REACT_APP_FLYWIRE_CALLBACK_VERSION || '2';
const REACT_APP_FLYWIRE_RECURRENT_TYPE = process.env.REACT_APP_FLYWIRE_RECURRENT_TYPE || 'tokenization';
const REACT_APP_OLE_API_KEY = process.env.REACT_APP_OLE_API_KEY || 'admin';
const REACT_APP_AWS_RUM_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID;
const REACT_APP_AWS_RUM_ENDPOINT = process.env.REACT_APP_AWS_RUM_ENDPOINT;
const REACT_APP_AWS_RUM_APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
const REACT_APP_AWS_RUM_REGION = process.env.REACT_APP_AWS_RUM_REGION;
const REACT_APP_TIME_REFRESH = process.env.REACT_APP_TIME_REFRESH || '300000';

export { 
    BASE_URL, 
    REACT_APP_FLYWIRE_SCRIPT_URL, 
    REACT_APP_FLYWIRE_ENV, 
    REACT_APP_FLYWIRE_CODE, 
    REACT_APP_FLYWIRE_CODE_US,
    REACT_APP_FLYWIRE_CODE_OTHER,
    REACT_APP_FLYWIRE_CALLBACK_URL, 
    REACT_APP_FLYWIRE_CALLBACK_VERSION,
    REACT_APP_FLYWIRE_RECURRENT_TYPE,
    REACT_APP_OLE_API_KEY,
    REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
    REACT_APP_AWS_RUM_ENDPOINT,
    REACT_APP_AWS_RUM_APPLICATION_ID,
    REACT_APP_AWS_RUM_REGION,
    REACT_APP_TIME_REFRESH
};